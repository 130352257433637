<template>
  <div>
    <div class="example-preview table-responsive">
      <table class="table table-vertical-center">
        <h4 class="card-title card-title--txt">Khóa học đã chọn</h4>
        <tbody class="card-pd">
        <tr v-for="(course_infor,index) in contract.student_register_courses" :key="index">
          <td>{{ course_infor.course.name }}</td>
          <td width="25%">
            <input v-model="coursePrice[index]" type="number" class="form-control input-course" v-on:input="changeCoursePrice(index)">
          </td>
          <td>{{ course_infor.course_sale}} %</td>
          <td>{{ parseInt(course_infor.course_fee) * (1 - parseInt(course_infor.course_sale)/100) | toThousandFilter}} ₫</td>
        </tr>
        <tr>
          <td><strong >Tổng giá khóa (1) :</strong></td>
          <td colspan="3" class="text-right"><strong class="text-danger">{{ totalCourseRegisterFee | toThousandFilter }} ₫</strong></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="payment-detail">
      <h5 class="card-title card-title--txt">Chi tiết thanh toán</h5>
      <div class="row" v-if="!isTransferred">
<!--        <div class="col-6">-->
<!--          <a href="javascript:" class="promotion-list" @click="showGifts">-->
<!--            <strong>Danh sách quà tặng</strong>-->
<!--            <span class="promotion-count">{{gifts.length}}</span>-->
<!--          </a>-->
<!--        </div>-->
        <div class="col-6">
          <p>Danh sách khuyến mãi:</p>
        </div>
        <div class="col-6">
          <a href="javascript:" class="promotion-list" @click="showVouchers">
            <strong>Danh sách Voucher </strong>
            <span class="promotion-count">{{promotionTotal}}</span>
          </a>
        </div>
      </div>
      <!-- <div class="row" v-if="promotionForContract"> -->
        <!-- <div class="col-4"> -->
          <!-- <p>Giảm giá mã Km:</p> -->
        <!-- </div> -->
        <!-- <div class="col-4"> -->
          <!-- <span class="promotion-icon"><i class="fas fa-minus-circle mr-2" style="cursor: pointer;" @click="removeContractPromotion"></i></span> -->
          <!-- <span class="promotion-list"><strong>{{promotionForContract.name}}</strong></span> -->
        <!-- </div> -->
        <!-- <div class="col-4"> -->
          <!-- <strong class="text-danger">{{ promotionForContract.value | toThousandFilter }} ₫</strong> -->
        <!-- </div> -->
      <!-- </div> -->
      <div class="row" v-for="(voucher,index) in vouchersForContract" :key="index">
        <div class="col-4">
          <p>Voucher {{++index}}:</p>
        </div>
        <div class="col-4">
          <span class="promotion-icon" v-if="!isTransferred">
<!--             Giảm giá mã Km-->
            <i v-if="voucher.type !== 0" class="fas fa-minus-circle mr-2" style="cursor: pointer;" @click="removeContractPromotion(index)"></i>
<!--             Voucher type 0-->
            <i v-else class="fas fa-minus-circle mr-2" style="cursor: pointer;" @click="removeContractVoucher(index)"></i>
          </span>
          <span v-if="voucher.type !== 0" class="promotion-list"><strong>{{'Quy đổi quà tặng - ' + voucher.name }}</strong></span>
          <span v-else class="promotion-list"><strong>{{voucher.name}}</strong></span>
        </div>
        <div class="col-4">
          <strong class="text-danger" v-if="voucher.unit === 1 ">{{ voucher.value | toThousandFilter }} ₫</strong>
          <strong class="text-danger" v-else>{{ voucher.value }} %</strong>
        </div>
      </div>

      <div class="row border-custom p-3">
        <div class="col-md-6 pb-2">
          <strong>Tổng khuyến mại (2)</strong>
        </div>
        <div class="col-md-6 pb-2 text-right">
          <strong>{{ totalDiscount | toThousandFilter }} ₫</strong>
        </div>
        <div class="col-md-6 pb-2" v-if="isWithdrawWithCourse">
          <strong><span>Giá trị khóa bị hủy (3)</span>
            <i
                class="fas fa-exclamation-circle ml-3"
                style="cursor: pointer; color: red"
                @click="openModalListCourses"
                v-tooltip="'Danh sách khóa bị hủy'"
            ></i>
          </strong>
        </div>
        <div class="col-md-6 pb-2 text-right" v-if="isWithdrawWithCourse">
          <strong>{{ totalCourseRefundFee | toThousandFilter }} ₫a</strong>
        </div>
        <div class="col-md-6 pb-2" v-if="isWithdrawWithCourse || (!isWithdrawWithCourse && totalDestroyFee > 0)">
          <strong>Phí hủy khóa
            <span v-if="isWithdrawWithCourse">(4)</span>
            <span v-if="!isWithdrawWithCourse">(3)</span>
          </strong>
        </div>
        <div class="col-md-6 text-right" v-if="isWithdrawWithCourse || (!isWithdrawWithCourse && totalDestroyFee > 0)">
          <strong>{{ totalDestroyFee | toThousandFilter }} ₫</strong>
        </div>
        <div class="col-md-6 pb-2" v-if="isTransferred">
          <strong>Khuyến mại sau thay đổi</strong>
        </div>
        <div class="col-md-6 text-right" v-if="isTransferred">
          <strong>{{ totalPromotionAfterTransferred | toThousandFilter }} ₫</strong>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-md-6 pb-2">
          <strong>Tổng giá trị hợp đồng
            <span v-if="isWithdrawWithCourse">(5) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(5) = (1) - (2) - (3) + (4)'"></i></span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee > 0">(4) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(4) = (1) - (2) + (3)'"></i></span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee === 0">(3) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(3) = (1) - (2) '"></i></span>
          </strong>
        </div>
        <div class="col-md-6 pb-2 text-right">
          <strong v-if="isWithdrawWithCourse">{{ totalPriceContract | toThousandFilter }} ₫</strong>
          <strong v-if="!isWithdrawWithCourse && totalDestroyFee > 0">{{ totalCourseRegisterFee - totalDiscount + totalDestroyFee | toThousandFilter }} ₫</strong>
          <strong v-if="!isWithdrawWithCourse && totalDestroyFee === 0">{{ totalCourseRegisterFee - totalDiscount | toThousandFilter }} ₫</strong>
        </div>
        <div class="col-md-6 text-center">
          <strong>Đã thanh toán
            <span v-if="isWithdrawWithCourse">(6)</span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee > 0">(5)</span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee === 0">(4)</span>
          </strong>
        </div>
        <div class="col-md-6 text-right">
          <strong>{{ contract.total_paid | toThousandFilter }} ₫</strong>
        </div>

        <div class="col-md-6 pt-2">
          <strong>Còn nợ
            <span v-if="isWithdrawWithCourse">
                        (7) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(7) = (5) - (6)'"></i>
                      </span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee > 0">
                        (6) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(6) = (4) - (5)'"></i>
                      </span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee === 0">
                        (5) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(5) = (3) - (4)'"></i>
                      </span>
          </strong>
        </div>
        <div class="col-md-6 pt-2 text-right">
          <strong v-if="isWithdrawWithCourse">{{ totalPriceContract - contract.total_paid | toThousandFilter }} ₫</strong>
          <strong v-if="!isWithdrawWithCourse && totalDestroyFee > 0">{{ (totalCourseRegisterFee - totalDiscount + totalDestroyFee) - contract.total_paid | toThousandFilter }} ₫</strong>
          <strong v-if="!isWithdrawWithCourse && totalDestroyFee === 0">{{ (totalCourseRegisterFee - totalDiscount) - contract.total_paid | toThousandFilter }} ₫</strong>
        </div>
      </div>
    </div>
    <list-course-refund :show-modal="showModalListCourses" @clicked="closeModalListCourses" :list-courses-refund="coursesRefund"></list-course-refund>
  </div>
</template>

<script>
import ListCourseRefund from "./ListCourseRefund";
export default {
  name: "PaymentDetail",
  components: {
    ListCourseRefund
  },
  props: {
    contract: {
      type: Object,
      default: () => { return {} }
    },
    isWithdrawWithCourse: {
      default: false
    },
    promotionForContract: {
      type: [Object, String, Array],
      default: () => { return '' }
    },
    vouchersForContract: {
      type: Array,
      default: () => { return [] }
    },
    coursePrice: {
      type: [Array],
      default: () => { return [] }
    },
    promotions: {
      type: [Array],
      default: () => { return [] }
    },
    vouchers: {
      type: [Array],
      default: () => { return [] }
    },
    totalDiscount: {
      type: [String, Number],
      default: () => { return 0 }
    },
    totalCourseRefundFee: {
      type: [String, Number],
      default: () => { return 0 }
    },
    totalDestroyFee: {
      type: [String, Number],
      default: () => { return 0 }
    },
    totalCourseRegisterFee: {
      type: [String, Number],
      default: () => { return 0 }
    },
    totalContractAmount: {
      type: [String, Number],
      default: () => { return 0 }
    },
    coursesRefund: {
      type: [Array],
      default: () => { return [] }
    },
    isTransferred: {
      type: Boolean,
      default: () => { return false }
    },
    totalPromotionAfterTransferred: {
      type: Number,
      default: () => { return 0 }
    },
    gifts: {
      type: Array,
      default: () => { return [] }
    },
    promotionTotal:{
      type: Number,
      default: () => { return 0 }
    }
  },
  data() {
    return {
      showModalListCourses: false
    }
  },
  computed: {
    totalPriceContract(){
      return this.totalCourseRegisterFee - this.totalDiscount - this.totalCourseRefundFee + this.totalDestroyFee;
    }
  },
  methods: {
    showGifts() {
      this.$emit('showPromotions')
    },
    showVouchers() {
      this.$emit('showVouchers')
    },
    removeContractPromotion(index){
      this.$emit('removeContractPromotion', index);
    },
    removeContractVoucher(index){
      this.$emit('removeContractVoucher', index);
    },
    changeCoursePrice(index){
      this.$emit('changeCoursePrice', index);
    },
    openModalListCourses : function (){
      this.showModalListCourses = true;
    },
    closeModalListCourses : function (){
      this.showModalListCourses = false;
    },
  }
}
</script>

<style scoped>

</style>
